.financialInfoForm {
  display: flex;
  flex-direction: column;

  .financialInfoHeader {
    padding-bottom: 10px;
    font-weight: bold;
  }
}

@media (min-width: map(breakpoints, generic, 800)) {
  .financialInfoForm {
    flex-direction: row;

    .financialInfoHeader {
      width: 50%;
    }

    .financialInfoSelectWrapper {
      width: 100%;
      padding-left: 10px;
    }

    .financialInfoSelect {
      width: 250px;
    }
  }
}

@media (min-width: map(breakpoints, generic, 1100)) {
  .financialInfoForm {
    .financialInfoHeader {
      width: 30%;
    }
  }
}
