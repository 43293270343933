.investorRelations {
  margin: 80px;
  max-width: 75vw;
  display: flex;

  .corporateMessage {
    font-size: map(fontSizes, large);
    color: map(colors, typography, secondaryText);
    margin-bottom: 16px;
  }

  .title {
    font-size: map(fontSizes, xxxlarge);
    margin: 0.5em 0;
    color: map(colors, typography, primaryText);
    font-weight: map(fontWeight, xxnormal);
    line-height: 1.23;
  }
}

/* DarkMode Styles */
.darkMode {
  .investorRelations {
    > div {
      color: map(colors, typography, darkBackgroundText);
    }
  }
}
