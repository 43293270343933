.summaryFieldBlock {
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  > div {
    width: 50%;
  }

  .questionColumn {
    padding-left: 20px;
  }

  .answerColumn {
    text-align: right;

    > strong {
      padding-left: 5px;
      word-break: break-word;
    }
  }
}
