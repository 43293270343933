/* ==== BeneficiariesForm ==== */
.beneficiariesForm {
  .headerWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: map(colors, background, value_4);
    padding: 5px 16px;
    border-radius: 25px;

    @media (max-width: map(breakpoints, generic, 800)) {
      margin: 16px 0;
      width: 90vw;
    }

    @media (max-width: map(breakpoints, generic, 1100)) {
      margin: 16px 0;
    }
  }

  .submitButton {
    color: map(colors, typography, darkBackgroundText);
    background-color: map(colors, utilities, successGreen);

    &.ant-btn-primary[disabled] {
      background-color: map(colors, typography, disabled);
      color: rgba(0, 0, 0, 0.25);
    }

    &:hover {
      opacity: 0.8;
    }
  }

  /* ==== BeneficiaryTable ==== */
  .headerTable {
    padding-top: 20px;
    margin-top: 32px;
    border: 1px solid map(colors, background, value_4);
    border-radius: 5px;

    > div:first-child {
      align-items: center;

      margin-left: 16px;
      margin-right: 16px;
    }

    .deleteButton {
      cursor: pointer;
      color: map(colors, typography, disabled);
      font-size: map(fontSizes, large);
      visibility: hidden;
    }

    .addAccountRow {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 0 16px;

      div {
        padding: 0 8px;
      }
    }
  }

  .primaryText {
    font-weight: map(fontWeight, bold);
    color: map(colors, branding, primary);
  }

  .contingentText {
    font-weight: map(fontWeight, bold);
    color: map(colors, typography, primaryText);
  }

  .allocationSelectWrapper {
    justify-content: space-between;
    display: flex;
    align-items: center;

    margin-bottom: 24px;

    .ant-form-item {
      padding: unset;
      margin: unset;
    }
  }

  .percentageHeader {
    font-size: map(fontSizes, default);
    font-weight: map(fontWeight, bold);
  }

  .percentage {
    font-size: map(fontSizes, large);
    font-weight: map(fontWeight, bold);

    margin-left: 80px;
  }

  .percentAllocatedSummary {
    background-color: map(colors, background, value_4);
    border: 1px solid map(colors, background, value_4);
    border-radius: 5px;

    text-align: center;
    font-weight: map(fontWeight, bold);

    padding: 16px;
    margin: 32px 0;
  }

  .percentRemaining {
    font-size: map(fontSizes, large);
    margin-left: 8px;
  }

  .addAccountRow {
    padding-top: 20px;
    display: flex;
  }

  .sidePadding {
    padding: 0 8px;
  }

  .addAccountButton {
    color: map(colors, branding, primary);

    &:hover {
      cursor: pointer;
      color: map(colors, branding, secondary);
    }
  }

  /* ==== Overiding ANT's Table ==== */
  .ant-table-tbody > tr > td {
    padding: 16px;
    border-bottom: unset;
  }

  .ant-table-thead > tr > th {
    background-color: inherit;
    color: map(colors, typography, disabled);
    font-weight: map(fontWeight, bold);
  }

  .ant-table-row {
    font-weight: map(fontWeight, xxnormal);

    &:hover {
      .ant-table-cell {
        background-color: map(colors, background, value_7);
      }

      .deleteButton {
        visibility: visible;
      }
    }

    /* If user is on mobile screen, they should always see the delete button since there is no hover event*/
    @media (max-width: map(breakpoints, generic, 600)) {
      .deleteButton {
        visibility: visible;
      }
    }
  }

  .ant-calendar-picker {
    width: 100%;
  }
}

/* Darkmode Overides */
.darkMode {
  .beneficiariesForm {
    .percentageHeader {
      color: map(colors, typography, primaryText);
    }

    .addAccountButton {
      color: map(colors, branding, secondary);
    }
  }
}
