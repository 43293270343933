.investmentExperienceForm {
  .fieldWrapper {
    display: flex;
    flex-direction: column;
  }
  .experienceHeader {
    padding-bottom: 10px;
    font-weight: bold;
  }

  .experienceLabels {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .labelWrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    width: 100%;
  }
}
@media (min-width: map(breakpoints, generic, 800)) {
  .investmentExperienceForm {
    .fieldWrapper {
      flex-direction: row;
    }
    .experienceHeader {
      width: 30%;
    }
    .sliderWrapper {
      width: 100%;
      padding-left: 10px;

      .experienceSlider {
        width: 100%;
      }
    }
  }
}

@media (min-width: map(breakpoints, generic, 1100)) {
  .investmentExperienceForm {
    .experienceHeader {
      width: 50%;
    }
  }
}

.optionsMarkLabelStyle {
  display: "flex";
  flex-direction: "column";
  margin-left: "15px";
}
