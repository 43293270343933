.errorMessageAlert {
  padding-bottom: 20px;

  .code {
    margin-top: 10px;
    padding: 10px;

    color: map(colors, typography, darkBackgroundText);
    background-color: map(colors, background, value_1);

    max-height: 100px;
    overflow-y: auto;
  }
}
