.documentsForm {
  .ant-legacy-form-item-control {
    line-height: unset;
  }

  .bodySection {
    background-color: map(colors, background, value_9_white);
    padding: 20px;
    border-radius: 2px;
    margin-bottom: 20px;
  }

  .irsBox {
    height: 95%;

    border: 2px solid map(colors, background, value_0_black);

    margin: 5px;
    padding: 5px;
  }

  .documentWrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .documentLink {
    cursor: pointer;
    color: map(colors, typography, link);
  }

  .documentIcon {
    padding-right: 10px;
    color: map(colors, branding, primary);
  }

  .documentLink:hover {
    color: map(colors, branding, secondary);
  }
}

/* DarkMode Styles */
.darkMode {
  .bodySection {
    background-color: map(colors, background, value_0_black);
  }

  .irsBox {
    border-color: map(colors, background, value_1);
  }

  .documentLink {
    color: map(colors, branding, secondary);
  }
}
