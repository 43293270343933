/* ==== Display Layout ====  */
.centeredContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexJustifyCenteredContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexColumnWrap {
  flex-flow: column wrap;
}

.flexRowNoWrap {
  flex-flow: row nowrap;
}

.flexJustifySpaceBetween {
  display: flex;
  justify-content: space-between;
}

.paddingBelow {
  > div {
    padding-bottom: 20px;
  }
}

/* ==== Typography/ Cursor ====  */
.centeredText {
  text-align: center;
}

.cursorPointer {
  &:hover {
    cursor: pointer;
  }
}

.requiredAsteriskIcon::before {
  display: inline-block;
  content: "*";
  color: map(colors, utilities, errorRed);
  font-family: SimSun, sans-serif;
  font-size: map(fontSizes, default);
  line-height: 1;
  margin-right: 4px;
}

/* ==== Colors ====  */
.successGreen {
  color: map(colors, utilities, successGreen);
}

.infoBlue {
  color: map(colors, branding, secondary);
}

.errorRed {
  color: map(colors, utilities, errorRed);
}
