.darkMode {
  /* ==== Global ==== */
  background-color: map(colors, darkMode, mainBackgroundColor);
  color: map(colors, typography, darkBackgroundText);

  /* ==== Ant Overide Themeing ==== */
  /* ==== Overiding <Form /> ==== */
  .ant-legacy-form-item-required {
    color: map(colors, typography, darkBackgroundText);
  }

  .ant-input {
    color: map(colors, typography, darkBackgroundText);
  }

  .ant-select-selector {
    color: map(colors, typography, darkBackgroundText);
  }

  /* ==== Overiding <Radio Button /> ==== */
  .ant-radio-button-wrapper-checked.ant-radio-button-wrapper-checked.ant-radio-button-wrapper-checked.ant-radio-button-wrapper-checked {
    color: map(colors, typography, darkBackgroundText);
    border-color: map(colors, background, value_3);
  }

  .ant-radio-button-wrapper-checked {
    border-color: map(colors, branding, secondary);
  }

  .ant-radio-button-wrapper {
    background-color: map(colors, background, value_0_black);
  }

  .ant-radio-wrapper {
    color: map(colors, typography, darkBackgroundText);
  }

  /* ==== Overiding <Alert /> ==== */
  .ant-alert-warning {
    background-color: map(colors, background, value_2);
  }

  .ant-alert-info {
    background-color: map(colors, branding, secondary);
    border-color: map(colors, branding, primary);
  }

  /* ==== Overiding <Button /> ==== */
  .ant-btn {
    color: map(colors, typography, darkBackgroundText);
    background-color: map(colors, background, value_0_black);

    &:hover {
      background-color: map(colors, darkMode, hoverColor);
    }
  }

  .ant-btn-link {
    background-color: unset;
  }

  .ant-tooltip-disabled-compatible-wrapper > button {
    background-color: map(colors, typography, disabled);
    color: map(colors, background, value_1);
  }

  /* ==== Overiding <Meta /> ==== */
  .ant-card-meta-title {
    color: map(colors, typography, darkBackgroundText);
  }

  /* ==== Overiding <Select /> ==== */
  .ant-select-selector.ant-select-selector.ant-select-selector.ant-select-selector {
    background-color: map(colors, background, value_0_black);
    border-color: map(colors, background, value_3);

    &:hover {
      background-color: map(colors, darkMode, hoverColor);
    }
  }

  /* The select dropdown content*/
  .ant-select-dropdown {
    background-color: map(colors, background, value_0_black);

    .ant-select-item-option {
      &:hover {
        background-color: map(colors, darkMode, hoverColor);
      }
    }

    .ant-select-item-option-active {
      background-color: map(colors, background, value_3);
    }

    .ant-select-item-option-content {
      color: map(colors, typography, darkBackgroundText);
    }

    .ant-select-item-option-selected {
      background-color: map(colors, background, value_1);
    }
  }

  .ant-select-arrow {
    color: map(colors, typography, darkBackgroundText);
  }

  /* ==== Overiding <Input /> ====  */
  .ant-input-affix-wrapper {
    background-color: map(colors, background, value_0_black);
    border-color: map(colors, background, value_3);

    &:hover {
      background-color: map(colors, darkMode, hoverColor);
    }

    /* Enabled Input  */
    .ant-input::placeholder {
      color: map(colors, typography, darkBackgroundText);
    }

    /* Disabled Input  */
    .ant-input-disabled {
      color: map(colors, typography, disabled);
    }

    /* Letting the background transparent to make it the same color as its parent 
    (inherit doesn't work due to the fact that the style applies differently) */
    .ant-input {
      background-color: transparent;
    }
  }

  .ant-input {
    color: map(colors, typography, darkBackgroundText);
  }

  /* The Input with "inputmode=tel" as attribute (telephone input) */
  .ant-legacy-form-item-children .ant-input[inputmode="tel"] {
    background-color: map(colors, background, value_0_black);
    border-color: map(colors, background, value_3);

    &:hover {
      background-color: map(colors, darkMode, hoverColor);
    }
  }

  .ant-legacy-form-item-label > label {
    color: map(colors, typography, darkBackgroundText);
  }

  /* ==== Overiding <Checkbox /> ====  */
  .ant-checkbox-wrapper {
    color: map(colors, typography, darkBackgroundText);
  }

  /* Removing the white background on error */
  .has-error {
    .ant-legacy-form-item-children {
      > input {
        /* Ant has 5000 pseudo selector attached to the .has-error, I'm sorry! */
        background-color: unset !important;
      }
    }

    .ant-input-affix-wrapper {
      background-color: unset !important;
      > input {
        background-color: unset !important;
      }
    }
  }
}
