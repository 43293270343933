.homeFooterButton {
  background-color: map(colors, utilities, successGreen);
  border: 1px solid map(colors, utilities, successGreen);
  height: 100%;
  margin-top: 100px;
  padding-bottom: 10px;
  padding-top: 10px;
  width: 300px;
}

.homeFooterButtonWrapper {
  display: flex;
  justify-content: center;
  width: 95%;
}
