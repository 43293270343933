.employmentInfoForm {
  .buttonGroupWrapper {
    width: 100%;

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      width: 224px;
      font-weight: map(fontWeight, bold);
      padding: 20px 0;

      &:hover {
        color: map(colors, typography, darkBackgroundText);
        background-color: map(colors, branding, hoverBackground);
      }

      @media (max-width: map(breakpoints, generic, 600)) {
        width: 100%;
      }
    }
  }
}

/* Darkmode Overides  */
.darkMode {
  .buttonGroupWrapper {
    .ant-radio-button-wrapper {
      color: map(colors, typography, darkBackgroundText);

      &:hover {
        background-color: map(colors, darkMode, hoverColor);
      }
    }
  }
}
