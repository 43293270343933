.addressFormSpinner {
  display: flex;
  align-items: center;

  .ant-spin-dot-item {
    background-color: map(colors, background, value_2);
  }

  .spinner {
    margin-left: -25px;
  }
}
