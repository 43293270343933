.ant-result {
  padding-left: 0;
  padding-right: 0;
}
.ant-result-subtitle {
  font-size: map(fontSizes, large);
}

.ant-form-item-label .ant-typography.ant-typography-secondary {
  font-size: map(fontSizes, small);
  font-style: italic;
  text-align: left;
  white-space: normal;
}

label > div {
  display: inline-block;
  line-height: 1.6;
  max-width: calc(100% - 12px);
}

.email-label,
.email-labelExtra,
.phone-label,
.phone-labelExtra,
.pin-label,
.pin-labelExtra {
  line-height: 1.6;
  text-align: left;
  white-space: normal;
}

@media (min-width: map(breakpoints, generic, 600)) {
  /* Email */
  .en .email-label {
    width: 108px;
  }
  .es .email-label {
    width: 135px;
  }
  .en .email-labelExtra {
    width: calc(100% - 108px);
  }
  .es .email-labelExtra {
    width: calc(100% - 135px);
  }

  /* Phone Number */
  .en .phone-label {
    width: 135px;
  }
  .es .phone-label {
    width: 165px;
  }
  .en .phone-labelExtra {
    width: calc(100% - 135px);
  }
  .es .phone-labelExtra {
    width: calc(100% - 165px);
  }

  /* PIN */
  .pin-label {
    width: 55px;
  }
  .pin-labelExtra {
    width: calc(100% - 55px);
  }
}

/* Darkmode Overides  */
.darkMode {
  .ant-layout {
    background-color: unset;
  }
}
