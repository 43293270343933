.notFound {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  weight: 100vw;
}

/* DarkMode Styles */
.darkMode {
  .notFound {
    .ant-result-subtitle {
      color: map(colors, typography, darkBackgroundText);
    }
  }
}
