.largeHeader {
  font-size: map(fontSizes, xxxlarge);
  font-weight: map(fontWeight, xxnormal);
}

.header {
  font-size: map(fontSizes, xxlarge);
  font-weight: map(fontWeight, bold);
}

.subheader {
  font-size: map(fontSizes, xlarge);
  font-weight: map(fontWeight, xnormal);
}

.body {
  font-size: map(fontSizes, large);
  font-weight: map(fontWeight, normal);
}

.link {
  color: map(colors, typography, link);
}

.reminderText {
  font-size: map(fontSizes, small);
  font-weight: map(fontWeight, xxnormal);
}

.meta {
  font-size: map(fontSizes, xsmall);
  color: map(colors, typography, meta);
}

.smallMeta {
  font-size: map(fontSizes, xxsmall);
  color: map(colors, typography, secondaryText);
}

/* Darkmode Overides  */
.darkMode {
  .largeHeader,
  .header,
  .subheader,
  .subtitle,
  .body,
  .reminderText,
  .meta,
  .smallMeta {
    color: map(colors, typography, darkBackgroundText);
  }

  .link {
    color: map(colors, branding, secondary);
  }
}
