body,
.siteBackground {
  align-items: center;
  background-color: map(colors, background, value_9_white);
  background-size: auto 8px;
}

.cardWrapper {
  margin: 0 auto;
  padding: 0 1em 2em 1em;
}

.cardWrapper > .ant-card {
  margin: 0 auto;
  max-width: 40em;
}

.logoWrapper {
  margin: 2em 0;
}

.ant-form-vertical {
  max-width: 550px;
}

.ant-form-item-required::before {
  display: none;
}

.ant-select-arrow {
  top: 15px;
}

/* ==== Overiding <Input /> ====  */
.ant-input-affix-wrapper {
  border-color: map(colors, background, value_5);

  /* Enabled Input  */
  .ant-input::placeholder {
    color: map(colors, background, value_5);
  }
}

.timeoutAlert {
  margin-top: 20px;
  margin-bottom: 10px;

  width: 100%;
}

@media (min-width: map(breakpoints, generic, 800)) {
  .logoWrapper {
    margin: 12vh 0 5em;
  }

  .cardWrapper {
    max-width: 1120px;
    padding: 0 1em 2em 1em;
  }

  .timeoutAlert {
    width: 50%;
  }
}

/* DarkMode Styles */
.darkMode {
  strong {
    color: map(colors, typography, darkBackgroundText);
  }

  p {
    color: map(colors, typography, darkBackgroundText);
  }

  .cardWrapper {
    .ant-card {
      background-color: map(colors, background, value_2);
      border-color: map(colors, background, value_2);

      color: map(colors, typography, darkBackgroundText);

      .ant-card-head {
        border-color: map(colors, background, value_3);

        color: map(colors, typography, darkBackgroundText);
      }
    }
  }
}
