/* ==== HeaderLogo.tsx ==== */
.largeHeaderLogo {
  width: 350px;
}

.headerLogo {
  width: 200px;
}

/* ==== SidebarContainer.tsx ==== */
.sidebarContainer {
  /* Hide sidebar when user is using a small screen */
  @media (max-width: map(breakpoints, specific, ipadMini)) {
    display: none;
  }

  > * {
    padding-bottom: 10px;
  }

  .minimap {
    color: map(colors, typography, meta);

    li {
      list-style-type: none;
      height: 80px;
      display: flex;

      &.complete {
        cursor: pointer;
      }
      &.complete,
      &.currentScreen {
        color: map(colors, typography, primaryText);
      }

      &.currentScreen {
        font-weight: map(fontWeight, bold);
      }

      &:first-child {
        .openCheck::before,
        .successCheck::before {
          display: none;
        }
      }
    }

    .screenNameContainer {
      display: flex;
      align-items: center;
      height: 20px;

      .screenName {
        width: 144px;
      }
    }

    .sidebarCheck {
      height: 20px;
      width: 20px;

      border-radius: 100%;
      border: 3px solid map(colors, utilities, successGreen);

      margin-left: 20px;
      z-index: 2;

      &::before {
        display: block;
        position: absolute;
        content: "";

        background-color: map(colors, utilities, successGreen);
        border: 2px solid map(colors, utilities, successGreen);

        height: 62px;
        z-index: -1;
        padding: 0;
        margin-bottom: 82px;
      }

      &.successCheck {
        background-color: map(colors, utilities, successGreen);

        svg {
          height: 12px;
          width: 12px;
          fill: map(colors, typography, darkBackgroundText);
        }
      }

      &.openCheck {
        background-color: map(colors, background, value_9_white);
      }
    }
  }
}

@media (max-width: map(breakpoints, generic, 800)) {
  .sidebarContainer {
    .minimap {
      padding-left: unset;

      .sidebarCheck {
        margin-left: unset;
      }
    }
  }
}

/* ==== SalesForceChat.tsx ==== */
.salesForceButton {
  background-color: map(colors, branding, primary);
  color: map(colors, typography, darkBackgroundText);
}

.salesForceLink {
  display: none;
}

@media (max-width: map(breakpoints, specific, ipadMini)) {
  .salesForceButton {
    display: none;
  }

  .salesForceLink {
    display: block;
  }
}

/* Darkmode Overides  */
.darkMode {
  .sidebarContainer {
    .minimap {
      .subtitle {
        /* The screens that the user has not got to yet */
        color: map(colors, background, value_2);
      }

      .currentScreen {
        .screenNameContainer {
          .screenName {
            /* The current screen in the mini map */
            font-weight: map(fontWeight, bold);
            color: map(colors, typography, darkBackgroundText);
          }
        }
      }

      li.complete {
        /* The finished screens */
        color: map(colors, background, value_4);
      }
    }

    .screenNameContainer {
      .openCheck {
        /* The center of the checkmark */
        background-color: map(colors, background, value_1);
      }
    }
  }

  .zenDesk {
    .zenDeskLink {
      color: map(colors, branding, secondary);
    }

    > div {
      > a {
        color: map(colors, branding, secondary);
      }
    }
  }
}
