.phoneNumberWrapper {
  > div {
    width: 100%;
    display: flex;

    input {
      height: 32px;
    }

    .countryCodeItem {
      width: 40%;
    }

    .phoneItem {
      width: 60%;
      padding-left: 10px;
    }
  }
}
