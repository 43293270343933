.embeddedServiceHelpButton .helpButton {
  right: 50px;
}

.embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #336094;
  font-family: "Arial", sans-serif;
}

.embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: 1px solid #336094;
}

.showDockableContainer .sidebarHeader h2 {
  color: white;
}

.dialogButton .uiButton .embeddedServiceSidebarButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.embeddedServiceSidebarFeature .messageArea a {
  color: white;
}

.dialogButtonContainer button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.waitingStateButtonContainer button {
  display: flex;
  justify-content: center;
  align-items: center;
}
