.trustedContactForm {
  .anticon svg {
    width: 20px;
    height: 20px;

    &:hover {
      cursor: pointer;
    }
  }
}
