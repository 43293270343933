.pageWrapper {
  /* ==== Global Wrapper Styles  */
  margin: 1%;

  .slideInWrapper {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contentBody {
    width: 100%;

    max-width: 1000px;
    padding: 20px 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > div {
      display: flex;
      justify-content: center;
    }

    /* Damn you loading spinner! */
    > div > .ant-spin-nested-loading {
      width: 100%;
    }

    .signUpBody {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 40px;
    }
  }

  /* ==== SplashScreen Wrapper  */
  .splashScreen {
    height: 100%;
    width: 100%;

    > video {
      display: none;
    }
  }

  .splashScreenWrapper {
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.16);

    border-radius: 5px;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 10px;

    > div {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
    }
  }

  @media (min-width: map(breakpoints, generic, 500)) {
    .splashScreen {
      max-width: 600px;
    }
  }

  @media (min-width: map(breakpoints, generic, 1100)) {
    .splashScreenWrapper {
      flex-direction: row;
      align-items: unset;
    }

    .splashScreen {
      max-width: 50%;

      display: flex;
      justify-content: center;

      position: relative;
      width: 150px;
    }

    .splashScreen > video {
      width: 100%;
      display: unset;
    }
  }

  @media (min-width: map(breakpoints, generic, 1600)) {
    .splashScreen > video {
      max-width: 800px;
    }
  }

  /* ==== Sidebar Wrapper  */
  .contentWrapper {
    display: flex;
    flex-direction: column-reverse;

    height: 100%;
    padding-top: 2%;

    .contentDisclosure {
      padding-top: 5%;
    }

    @media (min-width: map(breakpoints, generic, 800)) {
      .sidebar {
        display: flex;
        width: 400px;

        margin-right: 2%;
      }

      .contentDisclosure {
        display: unset;
      }
    }
  }

  @media (min-width: map(breakpoints, specific, ipadMini)) {
    .contentWrapper {
      flex-direction: row;
    }
  }
}
