.topBar {
  width: 100%;

  padding: 0px 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  > div:first-child {
    padding-bottom: 10px;
  }

  .topBarOptions {
    > div:first-child {
      padding-bottom: 10px;
    }
  }

  @media (min-width: map(breakpoints, generic, 800)) {
    justify-content: space-between;
    flex-direction: row;
    align-items: stretch;
  }

  @media (min-width: map(breakpoints, generic, 500)) {
    .topBarOptions {
      > div:first-child {
        padding-bottom: unset;
      }

      display: flex;
      flex-direction: unset;
    }
  }
}

.logoHidden {
  display: flex;
  flex-direction: row-reverse;

  > div:first-child {
    display: none;
  }
}
