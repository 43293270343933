.yesNoFormElement {
  .buttonGroupWrapper {
    display: flex;

    > label:hover {
      color: map(colors, typography, darkBackgroundText);
      background-color: map(colors, branding, hoverBackground);
    }

    > label {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 150px;
      height: 60px;

      margin-right: 10px;

      font-size: map(fontSizes, xlarge);
      font-weight: map(fontWeight, bold);
    }
  }
}

/* Darkmode Overides */
.darkMode {
  .yesNoFormElement {
    .buttonGroupWrapper {
      /* Editing the Button on Yes/No Citizen Form */
      .ant-radio-button-wrapper {
        background-color: map(colors, background, value_0_black);
        /* Editing hover on the Button on Yes/No Citizen Form */
        &:hover {
          background-color: map(colors, darkMode, hoverColor);
        }
      }
    }
  }
}
