.investmentObjectivesForm {
  > div {
    padding-bottom: 20px;
  }

  .marginAlertWrapper {
    display: flex;
    flex-direction: column;

    > div {
      padding-bottom: 10px;
    }

    .marginAlertButtonWrapper {
      justify-content: center;
      align-items: center;

      > button {
        padding-left: 10px;
      }
    }
  }

  .radioGroupWrapper {
    display: flex;
    flex-direction: column;

    .radioWrapper {
      display: flex;
      flex-direction: row;

      padding-bottom: 10px;
      margin-bottom: 10px;

      width: 100%;

      border-bottom: 1px solid map(colors, background, value_4);

      > input {
        margin-top: 8px;
      }

      .isRadioBtnDisabled {
        color: map(colors, typography, meta);
        cursor: not-allowed;
        pointer-events: none;
      }

      .radioDescriptionBody {
        display: flex;
        flex-direction: row;

        width: 100%;

        margin-left: 5px;

        .descriptionHeader {
          width: 30%;
        }

        .descriptionBody {
          padding-top: 3px;

          width: 70%;
        }
      }
    }
  }

  .objectiveDescription {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .objectiveDescriptionWrapper {
    border-top: 1px solid black;
    display: flex;
    height: 150px;
    justify-content: center;
    width: 100%;
  }
}
