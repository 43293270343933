.topBarWrapper {
  margin: 10px 0px;
}

.headerLogoWrapper {
  height: auto;
  margin: 0px auto;
  max-width: 275px;
  padding: 10em 0px 5em;
  width: 100%;
}
