.formButtonFooterWrapper {
  max-width: 500px;

  padding-top: 15px;

  > * {
    padding-top: 15px;
  }

  .successButton > span > button {
    height: 50px;
  }

  .successButton > button {
    border-color: map(colors, utilities, successGreen);
    background-color: map(colors, utilities, successGreen);

    height: 50px;
  }

  .goBackButtonRow {
    display: flex;
    justify-content: space-between;

    > button {
      padding: unset;
    }
  }

  button {
    &:focus,
    &:hover {
      opacity: 0.8;
    }
  }
}

/* Darkmode Overides  */
.darkMode {
  .formButtonFooterWrapper {
    button {
      &:hover {
        background-color: map(colors, utilities, successGreen);
        opacity: 0.8;
      }
    }
  }
}
