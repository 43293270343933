.signUp {
  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .hr {
    margin: 20px 0px;
    border-bottom: 1px solid map(colors, branding, primary);
  }
}
